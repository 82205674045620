.navbar {
  background-color: var(--background-light);
  color: var(--text-dark);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 10;
}

.dark .navbar {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.navbar .brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar .nav-links {
  display: flex;
  space-x: 1rem;
}

.navbar .nav-link {
  padding: 0.5rem 1rem;
  transition: color 0.3s;
}

.navbar .nav-link:hover {
  color: var(--primary-color);
}

.dark .navbar .nav-link:hover {
  color: var(--primary-color-dark);
}

.navbar .theme-toggle,
.navbar .language-toggle {
  display: flex;
  align-items: center;
  space-x: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--background-light);
  color: var(--text-dark);
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dark .navbar .theme-toggle,
.dark .navbar .language-toggle {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.navbar .theme-toggle:hover,
.navbar .language-toggle:hover {
  background-color: var(--primary-color-light);
}

.dark .navbar .theme-toggle:hover,
.dark .navbar .language-toggle:hover {
  background-color: var(--primary-color-dark);
}

.navbar .language-toggle .dropdown-menu {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--background-light);
  color: var(--text-dark);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  overflow: hidden;
  transition: all 0.3s;
}

.dark .navbar .language-toggle .dropdown-menu {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.navbar .language-toggle:hover .dropdown-menu {
  display: block;
}

.navbar .dropdown-menu button {
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navbar .dropdown-menu button:hover {
  background-color: var(--primary-color-light);
}

.dark .navbar .dropdown-menu button:hover {
  background-color: var(--primary-color-dark);
}

@media (max-width: 768px) {
  .navbar .nav-links {
    display: none;
  }

  .navbar .menu-toggle {
    display: block;
  }

  .navbar .menu-open .nav-links {
    display: flex;
    flex-direction: column;
    space-y: 1rem;
  }
}
