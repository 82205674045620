@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'devicon/devicon.min.css';

:root {
  --primary-color: #ff4f5a;
  --secondary-color: #4f4fff;
  --background-light: #FAFAFA;
  --background-dark: #1A202C;
  --text-light: #FAFAFA;
  --text-dark: #1A202C;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark body {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

/* Themes */
.bg-light {
  background-color: var(--background-light);
}

.bg-dark {
  background-color: var(--background-dark);
}

.text-dark {
  color: var(--text-dark);
}

.text-light {
  color: var(--text-light);
}

.header-fullscreen {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  flex-direction: column; /* Modifie pour permettre le réarrangement en mobile */
  background-color: var(--background-light);
  color: var(--text-dark);
}

.dark .header-fullscreen {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.header-content {
  width: 100%; /* Modifie pour prendre toute la largeur en mobile */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10%; /* Réduit le padding pour mobile */
  text-align: left;
  z-index: 2;
}

.header-content .intro-text {
  max-width: 600px;
}

.header-content .intro-text h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
}

.header-content .intro-text p {
  font-size: 24px;
  margin-bottom: 40px;
}

.header-content .intro-buttons {
  display: flex;
  flex-direction: column; /* Modifie pour empiler les boutons en mobile */
  gap: 10px;
}

.header-content .intro-buttons .btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.header-content .intro-buttons .primary-btn {
  background-color: #ff4757;
}

.header-content .intro-buttons .secondary-btn {
  border-color: black;
  background-color: transparent;
  color: black;
  border: 2px solid;
}

.dark .header-content .intro-buttons .primary-btn {
  background-color: #ff4757;
}

.dark .header-content .intro-buttons .secondary-btn {
  background-color: transparent ;
  color: white;
  border-color: white;
  border: 2px solid;
}

.header-background {
  width: 100%; /* Modifie pour prendre toute la largeur en mobile */
  height: 50vh; /* Modifie pour ajuster la hauteur de l'image en mobile */
  position: relative;
  overflow: hidden;
}

.header-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6) blur(1px);
}

.social-icons {
  position: fixed;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.social-icons ul li {
  margin: 10px 0;
}

.social-icons ul li a {
  font-size: 24px;
  text-decoration: none;
  transition: color 0.3s;
}

.social-icons ul li a:hover {
  color: #ff4757;
}

.social-icons p {
  margin: 20px 0 0;
  font-size: 14px;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-align: center;
  transform: rotate(180deg);
}

/* Media Queries */
@media (min-width: 768px) {
  .header-fullscreen {
    flex-direction: row; /* Remet en ligne pour les écrans plus larges */
  }

  .header-content {
    width: 50%;
    padding-left: 10%;
  }

  .header-content .intro-buttons {
    flex-direction: row;
  }

  .header-background {
    width: 50%;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .social-icons {
    position: static;
    transform: none;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
  }

  .social-icons ul {
    display: flex;
  }

  .social-icons ul li {
    margin: 0 10px;
  }

  .social-icons p {
    display: none;
  }
}

header {
  background-color: var(--background-light);
  color: var(--text-dark);
}

.dark header {
  background-color: var(--background-dark);
  color: var(--text-light);
}

