/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Whitney";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney"), url("whitneymedium.woff") format("woff");
}

@font-face {
  font-family: "Whitney Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney Medium Italic"),
    url("whitneymediumitalic.woff") format("woff");
}

@font-face {
  font-family: "Whitney Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney Bold"), url("whitneybold.woff") format("woff");
}

@font-face {
  font-family: "Whitney Semibold Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney Semibold Regular"),
    url("whitneysemibold.woff") format("woff");
}

@font-face {
  font-family: "Whitney Book Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney Book Regular"), url("whitneybook.woff") format("woff");
}

@font-face {
  font-family: "Whitney Light Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney Light Regular"), url("whitneylight.woff") format("woff");
}

@font-face {
  font-family: "Whitney Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney Semibold Italic"),
    url("WhitneySemiboldItalic.woff") format("woff");
}

@font-face {
  font-family: "Whitney Book Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney Book Italic"),
    url("whitneybookitalic.woff") format("woff");
}

@font-face {
  font-family: "Whitney Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Whitney Light Italic"),
    url("whitneylightitalic.woff") format("woff");
}
