.projects-section {
  padding: 5rem 0;
  background-color: var(--background-light);
  color: var(--text-dark);
}

.dark .projects-section {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.project-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 1.5rem;
  height: 100%;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.project-image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 0.5rem;
}

.project-tags .tag {
  background-color: rgba(0, 0, 0, 0.1); /* Ajuster le fond des tags pour mode clair */
  padding: 0.3rem 0.6rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  color: #000; /* Couleur du texte des tags en mode clair */
}

.dark .project-tags .tag {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff; /* Couleur du texte des tags en mode sombre */
}

.project-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.project-title {
  margin-bottom: 0.5rem;
}

.project-description {
  flex-grow: 1;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.btn-primary:hover {
  background-color: red;
}

.dark .btn-primary {
  background-color: var(--primary-color-dark);
}

.dark .btn-primary:hover {
  background-color: var(--primary-color-hover-dark);
}

.project-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}

.project-modal {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 15px;
  max-width: 800px;
  width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 100;
  position: relative;
  overflow-y: auto;
}

.modal-content {
  background: transparent;
  padding: 2rem;
  border-radius: 15px;
  max-width: 800px;
  width: 90%;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  color: var(--text-dark);
}

.dark .modal-content {
  background: transparent;
  backdrop-filter: blur(10px);
  color: var(--text-dark);
}

.modal-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.carousel-container {
  margin-bottom: 1rem;
}

.modal-links .modal-link {
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.modal-links .modal-link:hover {
  background-color: var(--secondary-color);
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: inherit;
}
