.experience-section {
  background-color: var(--background-light);
  color: var(--text-dark);
  padding: 5rem 0;
}

.dark .experience-section {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.section-title {
  text-align: left;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.experience-list {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  padding-left: 0.5rem;
}

.dark .experience-list {
  border-left: 2px solid rgba(255, 255, 255, 0.2);
}

.experience-company {
  margin-bottom: 1rem;
  cursor: pointer;
  padding-left: 0.5rem;
  transition: background 0.3s;
  position: relative;
  text-align: left;
}

.experience-company:hover {
  background: rgba(0, 0, 0, 0.1);
}

.dark .experience-company:hover {
  background: rgba(255, 255, 255, 0.1);
}

.experience-company.selected {
  background: rgba(0, 0, 0, 0.2);
  border-left: 4px solid #4f4fff;
  padding-left: 0.5rem;
}

.dark .experience-company.selected {
  background: rgba(255, 255, 255, 0.2);
}

.experience-details {
  margin-top: 0;
  border-left: 4px solid #4f4fff;
  padding-left: 1rem;
}

.experience-details h3, .experience-details p {
  text-align: left;
}

.company-link {
  cursor: pointer;
  transition: color 0.3s;
}

.company-link:hover {
  color: rgb(143, 48, 204);
}

.timeline {
  position: relative;
  padding: 2rem 0;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 7px; /* Adjust to align with dots */
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(0, 0, 0, 0.2);
}

.dark .timeline::before {
  background: rgba(255, 255, 255, 0.2);
}

.timeline-item {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  padding-left: 2rem;
}

.timeline-dot {
  position: absolute;
  left: 0; /* Align with the timeline line */
  top: 20px;
  width: 14px;
  height: 14px;
  background: #3182ce;
  border-radius: 50%;
  z-index: 1;
}

.dark .timeline-dot {
  background: #63b3ed;
}

.timeline-content {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-left: 2rem;
}

.dark .timeline-content {
  background: rgba(255, 255, 255, 0.1);
}
