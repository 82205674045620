:root {
  --primary-color: #ff4f5a;
  --secondary-color: #4f4fff;
  --background-light: #FAFAFA;
  --background-dark: #1A202C;
  --text-light: #FAFAFA;
  --text-dark: #1A202C;
}

body {
  margin: 0;
  @apply font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-light);
  color: var(--text-light);
}

.dark body {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

/* Themes */
.bg-light {
  background-color: var(--background-light);
}

.bg-dark {
  background-color: var(--background-dark);
}

.text-dark {
  color: var(--text-dark);
}

.text-light {
  color: var(--text-light);
}

/* Custom styles for About section */
#about {
  padding: 5rem 0;
  position: relative;
  overflow: hidden;
  background-color: var(--background-light);
  color: var(--text-dark);
}

.dark #about {
  background-color: var(--background-dark);
  color: var(--text-light);
}
