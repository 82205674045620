@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.footer-section {
    background-color: #f0f0f5;
    color: #e2e8f0; /* Light gray text */
    padding: 4rem 0;
    width: 100%;
    font-family: 'Roboto', sans-serif; /* Apply Roboto font */
    background: linear-gradient(145deg, #2a4365 30%, #1e3a8a 70%);
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
}

.dark .footer-section {
    background-color: #1a202c;
    color: #e2e8f0;
    background: linear-gradient(145deg, #2d3748 30%, #1a202c 70%);
}
.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
  }
}

.footer-content h2 {
  margin-bottom: 1rem;
  color: #f6ad55; /* Light orange heading */
  text-align: left;
  position: relative;
}

.footer-content h2:after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background: #f6ad55;
  margin-top: 0.5rem;
}

.footer-content p {
  margin-bottom: 1rem;
}

.footer-content a {
  color: #e2e8f0;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-content a:hover {
  color: #f6ad55; /* Brighter orange link hover */
}

.social-links ul {
  list-style: none;
  padding: 0;
}

.social-links li {
  margin-bottom: 0.5rem;
}

.social-links a {
  display: flex;
  align-items: center;
  color: #e2e8f0;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #f6ad55; /* Brighter orange social link hover */
}

.footer-content .btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #48bb78; /* Green button background */
  color: #fff;
  border-radius: 0.375rem;
  text-decoration: none;
  font-weight: 700;
  transition: background-color 0.3s, transform 0.3s;
}

.footer-content .btn:hover {
  background-color: #38a169; /* Darker green button hover */
  color: #fff;
  transform: translateY(-3px);
}