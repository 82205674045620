.skills-section {
  background-color: var(--background-light);
  color: var(--text-dark);
  padding: 5rem 0;
}

.dark .skills-section {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.section-title {
  text-align: left;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.tabs-container {
  margin-bottom: 2rem;
}

.tabs {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.tab-item {
  padding: 1rem 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
}

.tab-item:hover,
.tab-item.active {
  color: #4f4fff;
  border-bottom: 2px solid #4f4fff;
}

.tab-content {
  transition: opacity 0.3s;
}

.skill-card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 3rem;
  height: 3rem;
}

.skill-card p {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
