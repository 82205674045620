@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'devicon/devicon.min.css';


:root {
  --primary-color: #ff4f5a;
  --secondary-color: #4f4fff;
  --background-light: #FAFAFA;
  --background-dark: #1A202C;
  --text-light: #FAFAFA;
  --text-dark: #1A202C;
}

body {
  margin: 0;
  @apply font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-light);
  color: var(--text-light);
}

.dark body {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

/* Themes */
.bg-light {
  background-color: var(--background-light);
}

.bg-dark {
  background-color: var(--background-dark);
}

.text-dark {
  color: var(--text-dark);
}

.text-light {
  color: var(--text-light);
}


h2 {
  color: var(--primary-color);
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--primary-color);
}

.rotate-90 {
  transform: rotate(90deg);
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.animate-blink {
  animation: blink 1s infinite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: linear-gradient(-45deg, #ee765210, #e73c7e10, #23a5d510, #23d5ab10);
  background-size: 100% 100%;
  -webkit-animation: Gradient 5s ease infinite;
  -moz-animation: Gradient 5s ease infinite;
  animation: Gradient 5s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}